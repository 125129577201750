import { useMemo } from "react";

import type { AxiosRequestConfig, Method } from "axios";
import { useSelector } from "react-redux";

import { APP_VERSION } from "@/src/constants/AppConstant";
import axios from "@/src/core/AxiosFetcher";
import { generateHeaders, getDeviceContext } from "@/src/hooks/helpers/apiHelpers";
import useGetDebugMode from "@/src/hooks/useGetDebugMode";
import { getDebugHeaderState } from "@/src/stores/debugConfig/debugConfigReducer";
import { selectors as userSelectors } from "@/src/stores/user";
import { getAPIBaseUrl } from "@/src/utils/APIUtil";
import { captureException } from "@/src/utils/logging/SentryLogging";

export interface BaseFetcherConfig extends AxiosRequestConfig {
  enableIdempotency?: boolean;
}

type BaseFetcher<Res, Req> = (url: string, payload: Req, axiosConfig?: BaseFetcherConfig) => Promise<Res>;

export const createFetcher = <Res, Req>(accessToken?: string, additionalHeaders?: Record<string, string>) => {
  const fetcher: BaseFetcher<Res, Req> = async (url, payload, axiosConfig = {}) => {
    const { enableIdempotency = false, ...baseConfig } = axiosConfig;

    try {
      const result = await axios({
        url,
        method: "POST" as Method,
        headers: generateHeaders(accessToken, enableIdempotency, additionalHeaders),
        data: {
          deviceContext: getDeviceContext(),
          frontendVersion: APP_VERSION,
          ...payload,
        },
        baseURL: getAPIBaseUrl(),
        ...baseConfig,
      });
      return result?.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      captureException(error);
      throw error;
    }
  };
  return fetcher;
};

function useBaseFetcher<Res, Req>() {
  const accessToken = useSelector(userSelectors.accessTokenSelector);
  const debuggerState = useGetDebugMode();
  const additionalDebugHeader = getDebugHeaderState(debuggerState);
  return useMemo(() => {
    return createFetcher<Res, Req>(accessToken, additionalDebugHeader);
  }, [accessToken, additionalDebugHeader]);
}

export default useBaseFetcher;
